import Vue from 'vue';
/* 导入路由 */
import VueRouter from 'vue-router';
/* 导入注册我们自己的组件 */
// import home from '../components/Home.vue'
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'map',
		component: () => import('../components/map.vue')
	},
	{
		path: '/Hdmap',
		name: 'Hdmap',
		component: () => import('../components/Hdmap.vue')
	},
	{
		path: '/lachth',
		name: 'lachth',
		component: () => import('../components/lachth.vue')
	},
	{
		path: '/link',
		name: 'link',
		component: () => import('../components/link.vue')
	},
	{
		path: '/Main',
		name: 'Main',
		component: () => import('../components/Main.vue')
	},
	{
		path: '/new',
		name: 'new',
		component: () => import('../components/new.vue')
	},
	{
		path: '/nuber',
		name: 'nuber',
		component: () => import('../components/nuber.vue')
	},
	{
		path: '/pie',
		name: 'pie',
		component: () => import('../components/pie.vue')
	},
	{
		path: '/serve',
		name: 'serve',
		component: () => import('../components/serve.vue')
	},
]


/* 定义组件路由 */
const router = new VueRouter({
	routes
})

//导出路由对象
export default router;